import { SignIn } from '@clerk/clerk-react'
import { Trans as T } from '@lingui/react/macro'
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'
import { Authenticated, Unauthenticated } from 'convex/react'

import { Shell } from '~/components/app/Shell'

export const Route = createFileRoute('/_app')({
  beforeLoad: async ({ context }) => {
    const { isAuthenticated, isLoading } = await context.auth
    if (!isLoading && !isAuthenticated) throw redirect({ to: '/' })
  },
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <Shell>
      <Authenticated>
        <Outlet />
      </Authenticated>
      <Unauthenticated>
        <div className="flex flex-col items-center justify-center gap-4 p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-rose-400">
              <T>Unauthorized</T>
            </h2>
            <p className="text-muted-foreground text-sm">
              <T>You must be logged in first&hellip;</T>
            </p>
          </div>
          <SignIn forceRedirectUrl={window.location.href} />
        </div>
      </Unauthenticated>
    </Shell>
  )
}

import React, { Suspense } from 'react'
import { Control } from 'react-hook-form'
import { create } from 'zustand'

import { Button } from '~/components/ui/Button'

const LazyRouterDevTools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      )

const LazyQueryDevTools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : React.lazy(() => import('@tanstack/react-query-devtools').then((res) => ({ default: res.ReactQueryDevtools })))

const LazyHookFormDevTools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : React.lazy(() =>
        import('@hookform/devtools').then((res) => ({
          default: res.DevTool,
        })),
      )

export const HookFormDevTools = ({ control }: { control: unknown }) => (
  <Suspense>
    <LazyHookFormDevTools control={control as Control} />
  </Suspense>
)

export const RouterDevTools = () => (
  <Suspense>
    <LazyRouterDevTools />
  </Suspense>
)

export const QueryDevTools = () => (
  <Suspense>
    <LazyQueryDevTools />
  </Suspense>
)

interface DevToolsStore {
  isDevToolsOpen: boolean
  setIsDevToolsOpen: (isDevToolsOpen: boolean) => void
}

export const useDevTools = create<DevToolsStore>()((set) => ({
  isDevToolsOpen: false,
  setIsDevToolsOpen: (isDevToolsOpen: boolean) => {
    set({ isDevToolsOpen })
  },
}))

export function DevToolsToggle() {
  const { isDevToolsOpen, setIsDevToolsOpen } = useDevTools()
  return (
    <Button
      icon={isDevToolsOpen ? 'xmark' : 'gears'}
      onClick={() => {
        setIsDevToolsOpen(!isDevToolsOpen)
      }}
      size="sm"
    />
  )
}

import { convexQuery } from '@convex-dev/react-query'
import { createFileRoute } from '@tanstack/react-router'

import { api } from '@/convex/api'
import { Id } from '@/convex/dataModel'

export const Route = createFileRoute('/_app/projects_/$id')({
  beforeLoad: ({ params }) => {
    return {
      pledgeQueryOptions: convexQuery(api.pledges.getPledge, { id: params.id as Id<'pledges'> }),
    }
  },
  loader: async ({ context }) => {
    // TODO: Figure this shit out.
    const { isAuthenticated, isLoading } = await context.auth
    if (!isAuthenticated || isLoading) return
    await context.queryClient.ensureQueryData(context.pledgeQueryOptions)
  },
})

import { Link, type LinkProps } from '@tanstack/react-router'
import React from 'react'

import { cva, cx, type VariantProps } from '@/lib/cva'
import { Icon, type Icon as IconProps } from '~/components/ui/Icon'

export type AsButtonProps = BaseProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> &
  React.RefAttributes<HTMLButtonElement> & {
    href?: never
    locale?: never
    native?: never
  }

export type AsLinkProps = BaseProps &
  Omit<LinkProps, keyof BaseProps> &
  React.RefAttributes<HTMLAnchorElement> & {
    native?: boolean
  }

export type BaseProps = React.RefAttributes<HTMLElement> &
  VariantProps<typeof button> & {
    children?: React.ReactNode
    className?: React.HTMLAttributes<HTMLElement>['className']
    icon?: '' | IconProps['name']
    iconPosition?: '' | 'left' | 'right'
    iconProps?: Omit<IconProps, 'name'>
    innerClassName?: React.HTMLAttributes<HTMLElement>['className']
    loading?: boolean
  }

export type Button = AsButtonProps | AsLinkProps

const CURRENT_STATES = ['active', 'current', 'open']

// TODO: handle a11y concerns https://www.w3.org/WAI/ARIA/apg/patterns/button/
export function Button({
  children,
  className,
  current,
  disabled,
  href,
  icon,
  iconPosition,
  iconProps = {},
  innerClassName,
  intent,
  loading,
  native,
  size,
  variant,
  ...rest
}: Button) {
  iconPosition = iconPosition ?? 'right'
  disabled = disabled ?? loading

  if (iconPosition === '') iconPosition = 'right'
  if (icon === '') icon = undefined

  const hasIcon = !!icon

  current = current ?? ('data-state' in rest && CURRENT_STATES.includes(rest['data-state'] as string))
  const iconOnly = hasIcon && (!children || children === '')

  if (iconOnly) {
    iconProps.fixedWidth = iconProps.fixedWidth ?? true
    variant = variant ?? 'icon'
  }

  if (loading) {
    if (icon) {
      iconProps.spin = iconProps.spin ?? true
      icon = 'spinner-third'
    }
  }

  const iconElement = icon ? <Icon name={icon} {...iconProps} /> : null

  const contentElement = (
    <span className={cx(content({ current, disabled, iconOnly, intent, size, variant }), innerClassName)}>
      {iconPosition === 'left' && iconElement}
      {children}
      {iconPosition === 'right' && iconElement}
    </span>
  )

  const props = {
    'aria-disabled': disabled,
    className: cx(button({ current, disabled, intent, loading, size, variant }), className),
    disabled,
  } as Record<string, unknown>

  if (!disabled) {
    delete props['aria-disabled']
    delete props.disabled
  }

  if (href || 'to' in rest) {
    const LinkComponent = native ? 'a' : Link

    if (href) props.href = href

    return (
      <LinkComponent {...props} {...(rest as AsLinkProps)}>
        {contentElement}
      </LinkComponent>
    )
  } else {
    return (
      <button {...props} type="button" {...(rest as AsButtonProps)}>
        {contentElement}
      </button>
    )
  }
}

const button = cva({
  base: ['transition-all active:opacity-80 text-white focus-visible:shadowed-focus'],
  compoundVariants: [
    {
      className: 'border border-teal-500 bg-teal-700',
      intent: 'primary',
      variant: 'solid',
    },
    {
      className: 'border border-violet-500 bg-violet-700',
      intent: 'secondary',
      variant: 'solid',
    },
    {
      className: 'border border-rose-500 bg-rose-700',
      intent: 'destructive',
      variant: 'solid',
    },
    {
      className: 'border border-slate-500 bg-slate-700',
      intent: 'none',
      variant: 'solid',
    },
    {
      className: 'opacity-80',
      disabled: true,
      loading: true,
    },
    {
      className: 'text-teal-200',
      intent: 'primary',
      variant: 'icon',
    },
    {
      className: 'text-violet-200',
      intent: 'secondary',
      variant: 'icon',
    },
    {
      className: 'text-rose-200',
      intent: 'destructive',
      variant: 'icon',
    },
    {
      className: 'bg-focus-highlight shadowed-focus',
      current: true,
      variant: 'icon',
    },
  ],
  defaultVariants: {
    current: false,
    disabled: false,
    intent: 'none',
    loading: false,
    size: 'md',
    variant: 'flat',
  },
  variants: {
    current: {
      false: '',
      true: '',
    },
    disabled: {
      true: 'pointer-events-none opacity-50',
    },
    intent: {
      destructive: '',
      none: '',
      primary: '',
      secondary: '',
    },
    loading: {
      true: 'pointer-events-none',
    },
    size: {
      lg: 'rounded-md',
      md: 'rounded-md',
      sm: 'rounded-sm focus:rounded-sm',
    },
    variant: {
      flat: '',
      ghost: '',
      icon: 'focus:bg-focus-highlight focus:shadowed-focus',
      nil: 'focus-visible:bg-focus-highlight',
      solid: 'group active:opacity-100',
    },
  },
})

const content = cva({
  base: ['flex items-center justify-center gap-x-2 leading-normal transition-all'],
  compoundVariants: [
    {
      className: 'border border-teal-300 text-teal-300 hover:bg-teal-500/10',
      intent: 'primary',
      variant: 'ghost',
    },
    {
      className: 'border border-violet-300 text-violet-300 hover:bg-violet-500/10',
      intent: 'secondary',
      variant: 'ghost',
    },
    {
      className: 'border border-rose-300 text-rose-300 hover:bg-rose-500/10',
      intent: 'destructive',
      variant: 'ghost',
    },
    {
      className: 'border border-teal-700',
      intent: 'primary',
      variant: 'solid',
    },
    {
      className: 'border border-violet-700',
      intent: 'secondary',
      variant: 'solid',
    },
    {
      className: 'border border-rose-700',
      intent: 'destructive',
      variant: 'solid',
    },
    {
      className: 'border border-slate-700',
      intent: 'none',
      variant: 'solid',
    },
  ],
  defaultVariants: {
    current: false,
    disabled: false,
    iconOnly: false,
    intent: 'none',
    size: 'md',
    variant: 'flat',
  },
  variants: {
    current: {
      false: '',
      true: '',
    },
    disabled: {
      true: '',
    },
    iconOnly: {
      false: 'px-2',
      true: 'aspect-square',
    },
    intent: {
      destructive: 'bg-rose-600 hover:bg-rose-500 group-focus-visible:bg-rose-500',
      none: 'bg-slate-600 hover:bg-slate-500 group-focus-visible:bg-slate-500',
      primary: 'bg-teal-600 hover:bg-teal-500 group-focus-visible:bg-teal-500',
      secondary: 'bg-violet-600 hover:bg-violet-500 group-focus-visible:bg-violet-500',
    },
    size: {
      lg: 'rounded-md text-lg h-10',
      md: 'rounded-md text-base h-8',
      sm: 'rounded-sm text-sm h-6',
    },
    variant: {
      flat: '',
      ghost: 'bg-transparent',
      icon: 'bg-transparent rounded h-auto hover:bg-transparent',
      nil: 'bg-transparent p-0 h-auto hover:bg-transparent text-inherit [font-size:inherit]',
      solid:
        'transform -mx-[1px] group-hover:-translate-y-1 group-focus-visible:-translate-y-1 -translate-y-0.5 active:border-transparent active:translate-y-[2px]',
    },
  },
})

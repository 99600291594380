import { cva, VariantProps } from '@/lib/cva'

export function Wordmark({ large }: VariantProps<typeof wordmark>) {
  return (
    <span className={wordmark({ large })}>
      <span className="text-teal-400">{'Backer'}</span>
      <span className="text-teal-600">{'.'}</span>
      <span className="text-amber-400">{'Quest'}</span>
    </span>
  )
}

const wordmark = cva({
  base: 'casual font-extrablack italic',
  variants: {
    large: {
      false: 'text-base',
      true: 'text-3xl',
    },
  },
})

import { ClerkProvider } from '@clerk/clerk-react'
import { Appearance, LocalizationResource } from '@clerk/types'
import { useEffect, useState } from 'react'

import { useLocaleStore } from '~/hooks/use-locale-store'

const localizations = {
  'en-US': () => import('@clerk/localizations/en-US').then((mod) => mod.enUS),
  'es-ES': () => import('@clerk/localizations/es-ES').then((mod) => mod.esES),
  'fr-FR': () => import('@clerk/localizations/fr-FR').then((mod) => mod.frFR),
  'it-IT': () => import('@clerk/localizations/it-IT').then((mod) => mod.itIT),
  'ja-JP': () => import('@clerk/localizations/ja-JP').then((mod) => mod.jaJP),
  'pseudo-LOCALE': () => import('@clerk/localizations/en-US').then((mod) => mod.enUS),
  'zh-CN': () => import('@clerk/localizations/zh-CN').then((mod) => mod.zhCN),
}

interface LocalizedClerkProviderProps {
  appearance: Appearance
  children: React.ReactNode
  publishableKey: string
}

export function LocalizedClerkProvider({ appearance, children, publishableKey }: LocalizedClerkProviderProps) {
  const currentLocale = useLocaleStore((state) => state.currentLocale)
  const [localization, setLocalization] = useState<LocalizationResource | undefined>(undefined)

  useEffect(() => {
    const loadLocalization = async () => {
      const mod = await localizations[currentLocale as keyof typeof localizations]()
      setLocalization(mod)
    }

    void loadLocalization()
  }, [currentLocale])

  return (
    <ClerkProvider appearance={appearance} localization={localization} publishableKey={publishableKey}>
      {children}
    </ClerkProvider>
  )
}

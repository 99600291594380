import { i18n, Messages } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useEffect } from 'react'

import { useLocaleStore } from '~/hooks/use-locale-store'

// TODO: Can we not do this? only an error in dev? Annoying error in console
i18n.loadAndActivate({ locale: 'en-US', messages: {} })

export function LocaleProvider({ children }: { children: React.ReactNode }) {
  const { currentLocale } = useLocaleStore()

  useEffect(() => {
    document.documentElement.setAttribute('lang', currentLocale)
    void loadCatalog(currentLocale)
  }, [currentLocale])

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}

async function loadCatalog(locale: string) {
  const { messages } = (await import(`../../locales/${locale}/messages.po`)) as { messages: Messages }
  i18n.loadAndActivate({ locale, messages })
}

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import * as icons from '@/lib/icons'

export type IconName = keyof typeof icons

const iconMap = icons as Record<IconName, IconDefinition>
export type Icon = Omit<FontAwesomeIconProps, 'icon' | 'name'> & {
  name: IconName
}

export function Icon({ name, ...rest }: Icon) {
  return <FontAwesomeIcon icon={iconMap[name]} {...rest} />
}

import { Trans as T } from '@lingui/react/macro'

import { Shell } from '~/components/app/Shell'
import { Button } from '~/components/ui/Button'

import { Stack } from './layouts/Stack'

export function NotFound({ children }: { children?: React.ReactNode }) {
  return (
    <Shell>
      <Stack className="gap-8 p-8">
        {children ?? (
          <>
            <h1 className="text-2xl font-extrabold">
              <T>Oops! We couldn&apos;t find that page.</T>
            </h1>
            <p className="casual text-sm text-slate-200">
              <T>
                What you are looking for doesn&apos;t seem to exist (anymore) or you don&apos;t have access to it.{' '}
                <span className="italic">If you think this is an error, please contact support.</span>
              </T>
            </p>
          </>
        )}

        <p className="flex flex-row items-center gap-2">
          <Button icon="house-person-return" intent="primary" to="/">
            <T>Go Home</T>
          </Button>
          <Button
            icon="dumpster-fire"
            intent="secondary"
            onClick={() => {
              window.history.back()
            }}
          >
            <T>Go back</T>
          </Button>
        </p>
      </Stack>
    </Shell>
  )
}

import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale'
import posthog from 'posthog-js'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const LOCALES: { language: string; locale: SupportedLocale }[] = [
  { language: 'Deutsch', locale: 'de-DE' },
  { language: 'English', locale: 'en-US' },
  { language: 'Español', locale: 'es-ES' },
  { language: 'Français', locale: 'fr-FR' },
  { language: 'Italiano', locale: 'it-IT' },
  { language: '日本語', locale: 'ja-JP' },
  { language: '中文', locale: 'zh-CN' },
]

posthog.onFeatureFlags(function () {
  if (posthog.isFeatureEnabled('PseudoLocale')) {
    if (!LOCALES.some((l) => l.locale === 'pseudo-LOCALE')) {
      LOCALES.push({ language: 'Ƥśēũďō', locale: 'pseudo-LOCALE' })
    }
  }
})

export type SupportedLocale = 'de-DE' | 'en-US' | 'es-ES' | 'fr-FR' | 'it-IT' | 'ja-JP' | 'pseudo-LOCALE' | 'zh-CN'

interface LocaleStore {
  currentLocale: string
  setLocale: (locale: string) => void
}

const DEFAULT_FALLBACK = () => 'en-US'

export const useLocaleStore = create<LocaleStore>()(
  persist(
    (set) => ({
      currentLocale: DEFAULT_FALLBACK(),
      detectLocale: () => {
        const detectedLocale = detect(fromStorage('locale'), fromNavigator, DEFAULT_FALLBACK)
        return LOCALES.some((l) => l.locale === detectedLocale) ? detectedLocale : DEFAULT_FALLBACK()
      },
      setLocale: (locale: string) => {
        if (LOCALES.some((l) => l.locale === locale)) {
          window.localStorage.setItem('locale', locale)
          set({ currentLocale: locale })
        }
      },
    }),
    {
      name: 'locale-storage',
    },
  ),
)

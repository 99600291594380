import { cva, VariantProps } from '@/lib/cva'

import { Icon } from './Icon'

export type Loading = VariantProps<typeof loading>

export function Loading({ size }: Loading) {
  return (
    <div className={loading({ size })} style={{ animationDelay: `${(Math.random() * -10).toFixed(2)}s` }}>
      <Icon name="spinner-third" spin />
    </div>
  )
}

const loading = cva({
  base: 'flex items-center flex-1  justify-center animate-rainbow-cycle',
  defaultVariants: {
    size: 'auto',
  },
  variants: {
    size: {
      auto: 'h-auto min-h-64 text-3xl',
      lg: 'min-h-64 text-4xl',
      md: 'min-h-32 text-2xl',
      sm: 'min-h-16 text-xl',
    },
  },
})

/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AppImport } from './routes/_app'
import { Route as AppProjectsIdImport } from './routes/_app/projects_.$id'

// Create Virtual Routes

const TermsLazyImport = createFileRoute('/terms')()
const PrivacyLazyImport = createFileRoute('/privacy')()
const IndexLazyImport = createFileRoute('/')()
const AppProjectsLazyImport = createFileRoute('/_app/projects')()

// Create/Update Routes

const TermsLazyRoute = TermsLazyImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/terms.lazy').then((d) => d.Route))

const PrivacyLazyRoute = PrivacyLazyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/privacy.lazy').then((d) => d.Route))

const AppRoute = AppImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const AppProjectsLazyRoute = AppProjectsLazyImport.update({
  id: '/projects',
  path: '/projects',
  getParentRoute: () => AppRoute,
} as any).lazy(() => import('./routes/_app/projects.lazy').then((d) => d.Route))

const AppProjectsIdRoute = AppProjectsIdImport.update({
  id: '/projects_/$id',
  path: '/projects/$id',
  getParentRoute: () => AppRoute,
} as any).lazy(() =>
  import('./routes/_app/projects_.$id.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/privacy': {
      id: '/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PrivacyLazyImport
      parentRoute: typeof rootRoute
    }
    '/terms': {
      id: '/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof TermsLazyImport
      parentRoute: typeof rootRoute
    }
    '/_app/projects': {
      id: '/_app/projects'
      path: '/projects'
      fullPath: '/projects'
      preLoaderRoute: typeof AppProjectsLazyImport
      parentRoute: typeof AppImport
    }
    '/_app/projects_/$id': {
      id: '/_app/projects_/$id'
      path: '/projects/$id'
      fullPath: '/projects/$id'
      preLoaderRoute: typeof AppProjectsIdImport
      parentRoute: typeof AppImport
    }
  }
}

// Create and export the route tree

interface AppRouteChildren {
  AppProjectsLazyRoute: typeof AppProjectsLazyRoute
  AppProjectsIdRoute: typeof AppProjectsIdRoute
}

const AppRouteChildren: AppRouteChildren = {
  AppProjectsLazyRoute: AppProjectsLazyRoute,
  AppProjectsIdRoute: AppProjectsIdRoute,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '': typeof AppRouteWithChildren
  '/privacy': typeof PrivacyLazyRoute
  '/terms': typeof TermsLazyRoute
  '/projects': typeof AppProjectsLazyRoute
  '/projects/$id': typeof AppProjectsIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '': typeof AppRouteWithChildren
  '/privacy': typeof PrivacyLazyRoute
  '/terms': typeof TermsLazyRoute
  '/projects': typeof AppProjectsLazyRoute
  '/projects/$id': typeof AppProjectsIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/_app': typeof AppRouteWithChildren
  '/privacy': typeof PrivacyLazyRoute
  '/terms': typeof TermsLazyRoute
  '/_app/projects': typeof AppProjectsLazyRoute
  '/_app/projects_/$id': typeof AppProjectsIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '' | '/privacy' | '/terms' | '/projects' | '/projects/$id'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '' | '/privacy' | '/terms' | '/projects' | '/projects/$id'
  id:
    | '__root__'
    | '/'
    | '/_app'
    | '/privacy'
    | '/terms'
    | '/_app/projects'
    | '/_app/projects_/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  AppRoute: typeof AppRouteWithChildren
  PrivacyLazyRoute: typeof PrivacyLazyRoute
  TermsLazyRoute: typeof TermsLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  AppRoute: AppRouteWithChildren,
  PrivacyLazyRoute: PrivacyLazyRoute,
  TermsLazyRoute: TermsLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/privacy",
        "/terms"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/_app": {
      "filePath": "_app.tsx",
      "children": [
        "/_app/projects",
        "/_app/projects_/$id"
      ]
    },
    "/privacy": {
      "filePath": "privacy.lazy.tsx"
    },
    "/terms": {
      "filePath": "terms.lazy.tsx"
    },
    "/_app/projects": {
      "filePath": "_app/projects.lazy.tsx",
      "parent": "/_app"
    },
    "/_app/projects_/$id": {
      "filePath": "_app/projects_.$id.tsx",
      "parent": "/_app"
    }
  }
}
ROUTE_MANIFEST_END */

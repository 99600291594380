import { Link, type LinkProps } from '@tanstack/react-router'

import { cva, cx, VariantProps } from '@/lib/cva'

type TextLink = LinkProps & React.HTMLAttributes<HTMLElement> & VariantProps<typeof textLink>

function TextLink({ children, className, underline, ...props }: TextLink) {
  if (props.href) {
    return (
      <a className={cx(textLink({ underline }), className)} {...props}>
        {children}
      </a>
    )
  }

  return (
    <Link className={cx(textLink({ underline }), className)} {...props}>
      {children}
    </Link>
  )
}

const textLink = cva({
  base: [
    'text-sky-400 hover:text-sky-300 transition-all hover:casual hover:font-bold hover:oblique focus-visible:shadowed-focus focus-visible:bg-focus-highlight',
  ],
  defaultVariants: {
    underline: false,
  },
  variants: {
    underline: {
      true: 'underline',
    },
  },
})
export { TextLink }

import { Select } from '~/components/form/inputs/Select'
import { Icon } from '~/components/ui/Icon'
import { LOCALES, useLocaleStore } from '~/hooks/use-locale-store'

export function LocaleSwitcher() {
  const { currentLocale, setLocale } = useLocaleStore()

  const handleLocaleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocale(e.target.value)
    window.location.reload()
  }

  return (
    <div className="flex items-center gap-2">
      <Icon name="language" />
      <Select
        onChange={handleLocaleChange}
        value={currentLocale}
        values={LOCALES.map(({ language, locale }) => ({ label: language, value: locale }))}
        variant="nil"
      />
    </div>
  )
}

import { Trans as T, useLingui } from '@lingui/react/macro'

import { DevToolsToggle } from '@/lib/dev-tools'
import { Center } from '~/components/layouts/Center'
import { LocaleSwitcher } from '~/components/LocaleSwitcher'
import { Icon } from '~/components/ui/Icon'
import { Wordmark } from '~/components/ui/Wordmark'

import { TextLink } from '../helpers/TextLink'

export function Footer() {
  const { i18n } = useLingui()
  const currentDate = new Date()

  return (
    <footer className="bg-slate-900 p-2 md:p-4">
      <Center>
        <div className="flex flex-row flex-wrap items-center justify-between gap-x-6 text-xs">
          <p>
            <T>
              <span>
                <TextLink to="/">
                  <Wordmark />
                </TextLink>{' '}
                is <Icon name="copyright" /> {i18n.date(currentDate, { year: 'numeric' })}
              </span>{' '}
              <span className="font-bold">Probable Panda</span>; <span className="semicasual italic">All rights reserved</span>. 🐼
            </T>
          </p>
          <div className="flex flex-row flex-wrap items-center gap-x-2">
            {process.env.NODE_ENV === 'development' && <DevToolsToggle />}
            <LocaleSwitcher />
            <p className="text-slate-300">
              <T comment="Made with <love> in <St. Petersburg, FL>">
                Made with <Icon beat className="text-rose-400" name="heart" style={{ '--fa-beat-scale': '105%' } as React.CSSProperties} />{' '}
                in <span className="text-sky-300">St. Petersburg, FL</span>
              </T>
            </p>
          </div>
        </div>
      </Center>
    </footer>
  )
}

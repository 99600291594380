import { useAuth } from '@clerk/clerk-react'
import { type Appearance } from '@clerk/types'
import { ConvexQueryClient } from '@convex-dev/react-query'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { type ConvexAuthState, ConvexReactClient, useConvexAuth } from 'convex/react'
import { ConvexProviderWithClerk } from 'convex/react-clerk'
import { StrictMode, useEffect } from 'react'
import ReactDOM from 'react-dom/client'

import { Provider as PostHogProvider } from '~/components/PostHog'
import { LocaleProvider } from '~/components/providers/LocaleProvider'

import './main.css'

import { LocalizedClerkProvider } from '~/components/providers/LocalizedClerkProvider'

import { Loading } from './components/ui/Loading'
import { RouterContext } from './routes/__root'
import { routeTree } from './routeTree.gen'

const convexClient = new ConvexReactClient(import.meta.env.VITE_CONVEX_URL as string)
const convexQueryClient = new ConvexQueryClient(convexClient)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: convexQueryClient.queryFn(),
      queryKeyHashFn: convexQueryClient.hashFn(),
    },
  },
})
convexQueryClient.connect(queryClient)

const router = createRouter({
  context: {} as RouterContext,
  defaultPendingComponent: () => <Loading />,
  defaultPreload: 'intent',
  routeTree,
  scrollRestoration: true,
  trailingSlash: 'never',
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

let resolveAuth: (client: ConvexAuthState) => void
const authClient = new Promise<ConvexAuthState>((resolve) => {
  resolveAuth = resolve
})

function App() {
  return (
    <LocalizedClerkProvider appearance={appearance} publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY as string}>
      <LocaleProvider>
        <ConvexProviderWithClerk client={convexClient} useAuth={useAuth}>
          <QueryClientProvider client={queryClient}>
            <PostHogProvider>
              <InnerApp />
            </PostHogProvider>
          </QueryClientProvider>
        </ConvexProviderWithClerk>
      </LocaleProvider>
    </LocalizedClerkProvider>
  )
}

function InnerApp() {
  const auth = useConvexAuth()

  useEffect(() => {
    resolveAuth(auth)
  }, [auth, auth.isLoading])

  return <RouterProvider context={{ auth: authClient, convexClient, convexQueryClient, queryClient }} router={router} />
}

const rootElement = document.getElementById('root')
if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  )
}

const appearance: Appearance = {
  variables: {
    colorBackground: '#354156', // --slate-700
    colorDanger: '#EE6E6C', // red-500
    colorInputBackground: '#1F283B', // slate-800
    colorInputText: '#F8FAFB', // slate-50
    colorNeutral: '#F8FAFB', // slate-50
    colorPrimary: '#7CE9D5', // teal-300
    colorSuccess: '#55D197', // emerald-400
    colorText: '#F8FAFB', // slate-50
    colorTextOnPrimaryBackground: '#354156', // slate-900
    colorTextSecondary: '#93A1B7', // slate-400
    colorWarning: '#55D197', // amber-400
    fontFamily: 'Recursive Variable',
  },
}

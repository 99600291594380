import { useMergeRefs } from '@floating-ui/react'
import { useRef } from 'react'

import { cva, VariantProps } from '@/lib/cva'
import { Icon, type IconName } from '~/components/ui/Icon'

export type Select = React.RefAttributes<HTMLSelectElement> &
  React.SelectHTMLAttributes<HTMLSelectElement> &
  VariantProps<typeof select> & {
    icon?: IconName
    values: Omit<React.OptionHTMLAttributes<HTMLOptionElement>, 'value'> &
      {
        label: string
        value: string
      }[]
  }

// TODO: Surface variants & Icon adornment

export function Select({ hasError = false, ref, values, variant, ...props }: Select) {
  const selectRef = useRef<HTMLSelectElement>(null)
  const mergedRef = useMergeRefs([selectRef, ref])

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    selectRef.current?.focus()
  }

  return (
    <div className={select({ hasError, variant })} onClick={handleClick}>
      <select {...props} className={selectInner({ hasError, variant })} ref={mergedRef}>
        {values.map((option) => (
          <option key={option.value} {...option}>
            {option.label}
          </option>
        ))}
      </select>
      <Icon aria-hidden="true" className="pointer-events-noneself-center justify-self-end text-slate-400" name="caret-down" />
    </div>
  )
}

const select = cva({
  base: [
    'flex items-center flex-1 gap-x-2 rounded-md border border-slate-600 bg-slate-900 px-2',
    'transition-all has-focus:shadowed-focus',
  ],
  variants: {
    hasError: {
      true: 'border-red-400',
    },
    variant: {
      nil: 'border-none',
    },
  },
})

const selectInner = cva({
  base: ['h-8 flex-1 appearance-none focus:outline-none'],
  variants: {
    hasError: {
      true: '',
    },
    variant: {
      nil: 'h-auto',
    },
  },
})

import { useUser } from '@clerk/clerk-react'
import { useLocation } from '@tanstack/react-router'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'

declare global {
  interface Window {
    posthog: typeof posthog
  }
}

export function PageView() {
  const location = useLocation()
  const { user } = useUser()

  useEffect(() => {
    let url = location.pathname
    if (location.searchStr !== '') url += `?${location.searchStr}`

    posthog.capture('$pageview', {
      $current_url: url,
    })
  }, [location])

  useEffect(() => {
    if (user && !posthog._isIdentified()) {
      posthog.identify(user.id, {
        email: user.primaryEmailAddress?.emailAddress,
        name: user.fullName,
      })
    } else {
      if (posthog._isIdentified()) {
        posthog.reset()
      }
    }
  }, [user])

  return null
}

export function Provider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY as string, {
      api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST as string,
      capture_pageview: false,
      ui_host: 'https://us.posthog.com',
    })
    window.posthog = posthog
  }, [])

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

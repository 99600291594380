import { ClerkLoaded, SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react'
import { t } from '@lingui/core/macro'
import { Trans as T } from '@lingui/react/macro'
import { useNavigate } from '@tanstack/react-router'
import { HTMLProps, Suspense } from 'react'

import { cx } from '@/lib/cva'
import { TextLink } from '~/components/helpers/TextLink'
import { Center } from '~/components/layouts/Center'
import { Button } from '~/components/ui/Button'
import { Icon } from '~/components/ui/Icon'
import { Wordmark } from '~/components/ui/Wordmark'

export function Header({ className }: { className?: HTMLProps<HTMLElement>['className'] }) {
  const navigate = useNavigate()

  const handleProjectsClick = async () => {
    await navigate({ to: '/projects' })
  }

  return (
    <header className={cx('bg-slate-900 p-2 md:px-4', className)}>
      <Center>
        <div className="flex flex-row flex-wrap items-center justify-between gap-x-6">
          <h2>
            <SignedIn>
              <TextLink to="/projects">
                <Wordmark large />
              </TextLink>
            </SignedIn>
            <SignedOut>
              <TextLink to="/">
                <Wordmark large />
              </TextLink>
            </SignedOut>
          </h2>
          <Suspense fallback={<UserButtonFallback />}>
            <ClerkLoaded>
              <SignedIn>
                <UserButton fallback={<UserButtonFallback />}>
                  <UserButton.MenuItems>
                    <UserButton.Action label={t`Projects`} labelIcon={<Icon name="list-check" />} onClick={handleProjectsClick} />
                  </UserButton.MenuItems>
                </UserButton>
              </SignedIn>
              <SignedOut>
                <SignInButton fallbackRedirectUrl="/projects" mode="modal">
                  <Button variant="ghost">
                    <T>Sign In</T>
                    <Icon name="person-to-portal" />
                  </Button>
                </SignInButton>
              </SignedOut>
            </ClerkLoaded>
          </Suspense>
        </div>
      </Center>
    </header>
  )
}

const UserButtonFallback = () => {
  return <div className="h-[28px] w-[28px] rounded-full bg-slate-700" />
}

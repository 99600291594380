import { Footer } from '~/components/app/Footer'
import { Header } from '~/components/app/Header'
import { Center } from '~/components/layouts/Center'

export function Shell({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-1 flex-col bg-slate-950 bg-[url('/images/tri.svg')]">
      <Header className="shadow-[0_0_0_3rem] shadow-slate-900" />
      <div className="flex-1 pt-2 pb-4 md:px-4">
        <Center>
          <main className="shadow-pane bg-slate-800 shadow-slate-900 not-has-data-full-bleed:p-2 md:rounded-md">{children}</main>
        </Center>
      </div>
      <Footer />
    </div>
  )
}

import { ConvexQueryClient } from '@convex-dev/react-query'
import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { ConvexReactClient } from 'convex/react'
import { ConvexAuthState } from 'convex/react'

import { QueryDevTools, RouterDevTools, useDevTools } from '@/lib/dev-tools'
import { NotFound } from '~/components/NotFound'
import { PageView } from '~/components/PostHog'

export interface RouterContext {
  auth: Promise<ConvexAuthState>
  convexClient: ConvexReactClient
  convexQueryClient: ConvexQueryClient
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  notFoundComponent: () => <NotFound />,
})

function RootComponent() {
  const { isDevToolsOpen } = useDevTools()
  return (
    <>
      <Outlet />
      {isDevToolsOpen && (
        <>
          <RouterDevTools />
          <QueryDevTools />
        </>
      )}
      <PageView />
    </>
  )
}
